import { action } from 'easy-peasy';
import { NotificationsModel } from './models';

export const notificationsStore: NotificationsModel = {
  socket: undefined,
  socketChannel: '',

  setSocket: action((state, payload) => {
    state.socket = payload;
  }),
  setSocketChannel: action((state, payload) => {
    state.socketChannel = payload;
  }),
};
