import React, { createContext, useState } from 'react'
import * as microsoftTeams from "@microsoft/teams-js"

type TMeetingSettings = MeetingSettings & {
  chatId: string,
  fontSize: number,
  user: microsoftTeams.app.UserInfo | undefined,
  teamsClient: microsoftTeams.HostClientType | undefined,
  context: TeamsContextType | undefined
  transcriptionSpeed: string
  meetingStage: boolean
}

type TSidePanelContext = {
  configurationSettings: TMeetingSettings,
  updateSettings: (settings: Partial<TMeetingSettings>) => void
}

export const ConfigurationContext = createContext({} as TSidePanelContext)

export const ConfigurationProvider = ({ children }: ContextProviderProps) => {

  React.useEffect(() => {

    if (window)
      window.Buffer
  }, [])

  const [configurationSettings, setSidePanelSettings] = useState<TMeetingSettings>({
    spokenLanguages: '',
    translationLanguage: '',
    preferredFont: '',
    fontSize: 18,
    chatId: '',
    user: undefined,
    teamsClient: undefined,
    context: undefined,
    transcriptionSpeed: 'medium',
    meetingStage: false
  })

  const updateSettings = (newSettings: Partial<TMeetingSettings>) => {
    setSidePanelSettings((prev) => ({ ...prev, ...newSettings }))
  }

  return <ConfigurationContext.Provider
    value={{
      configurationSettings,
      updateSettings
    }}>
    {children}
  </ConfigurationContext.Provider>

}