import React from "react";
import { PopUpContext } from "../../context/PopUpContext"

export const PopUpProvider = ({children}: {
    children: React.ReactNode
}) => {
    const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
    return (
        <PopUpContext.Provider value={{
            isPopUpOpen,
            setIsPopUpOpen: (value: boolean) => setIsPopUpOpen(value)
        }}>
            {children}
        </PopUpContext.Provider>
    )
}