import axios from "axios";
const apiUrl = `${process.env.GATSBY_API_URL}/api/users`;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    "x-functions-key": process.env.GATSBY_API_KEY,
  },
});

export const updateInstanceHeader = (tenantId: string) =>
  (axiosInstance.defaults.headers.common["organization_id"] = tenantId);

export const getUserPreferencesConfig = (userId: string) =>
  axiosInstance.get(`/${userId}`);

export const getUserProfileImage = (userId: string) =>
  axiosInstance.get(`/${userId}/profile-photo`);

export const updateUserPreferencesConfig = async (
  newConfig: UserSettings,
  organizerSettings: string[],
  userId: string
) =>
  axiosInstance.put(`/${userId}/preferences`, {
    participant_preferences: {
      origin_language: newConfig.spokenLanguages,
      target_language: newConfig.translationLanguage,
      preferred_font: newConfig.preferredFont,
    },
    organizer_preferences: {
      meeting_languages: organizerSettings,
    },
  });

export default {
  getUserPreferencesConfig,
  updateUserPreferencesConfig,
  getUserProfileImage,
};
