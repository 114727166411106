import { CCInteraction, InteractionMessage, UserCC } from './types';

export class CCInteractionProcessor {
  public static messageToCCInteraction(
    message: InteractionMessage
  ): CCInteraction {
    return {
      interactionId: message.interactionId,
      userName: message.userName,
      userId: message.userId,
      text: message.text,
      recognized: message.recognized,
      timestamp: message.timestamp,
      targetLanguage: message.targetLanguage,
      originalLanguage: message.originalLanguage,
    };
  }

  public static interactionsToDialogMessage(
    interactions: CCInteraction[],
    sort: 'asc' | 'desc'
  ): DialogContext[] {
    if (!interactions) {
      return [];
    }
    const innerArray: DialogContext[] = [];
    for (let i of interactions) {
      innerArray.push({
        key: i.key!,
        interactionId: i.interactionId,
        originalLanguage: i.originalLanguage,
        targetLanguage: i.targetLanguage,
        timestamp: i.timestamp,
        userId: i.userId,
        userName: i.userName,
        user_thumnbail: '',
        content: [
          {
            content: i.text,
            interaction_id: i.interactionId,
            timestamp: i.timestamp,
            recognized: i.recognized,
          },
        ],
      });
    }
    if (sort === 'desc') {
      return innerArray.sort((a, b) => {
        if (a.key! < b.key!) {
          return 1;
        }
        if (a.key! > b.key!) {
          return -1;
        }
        return 0;
      });
    } else {
      return innerArray.sort((a, b) => {
        if (a.key! < b.key!) {
          return -1;
        }
        if (a.key! > b.key!) {
          return 1;
        }
        return 0;
      });
    }
  }

  public static interactionsToUserClosedCaptions(
    interactions: CCInteraction[] | undefined
  ): UserCC[] {
    if (!interactions) {
      return [];
    }

    return interactions.reduce((acc, val): UserCC[] => {
      const found = acc.find((i) => i.userId === val.userId);
      const {
        text,
        timestamp,
        recognized,
        userName,
        userId,
        originalLanguage,
        targetLanguage,
        interactionId,
      } = val;

      if (found) {
        const interactionFound = found.ccs.find(
          (i) => i.interactionId === interactionId || i.text === text
        );
        if (!interactionFound) {
          found.ccs.push({ text, timestamp, recognized, interactionId });
        }
        return acc;
      }
      const uCC: UserCC = {
        userName,
        userId,
        ccs: [],
        originalLanguage,
        targetLanguage,
      };
      uCC.ccs.push({ text, timestamp, recognized, interactionId });
      acc.push(uCC);
      return acc;
    }, [] as UserCC[]);
  }
}
