import { app } from "@microsoft/teams-js";
import React, { createContext } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

interface ITeamsContext {
  context: app.Context | null;
  isReady: boolean;
  teamsSdk: typeof microsoftTeams;
}

export const TeamsContext = createContext<ITeamsContext | null>({
  context: null,
  isReady: false,
  teamsSdk: microsoftTeams,
});

export const useTeams = () => {
  const teamsApp = React.useContext(TeamsContext);
  return teamsApp as ITeamsContext;
}