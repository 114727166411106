import { action } from 'easy-peasy';
import { ExternalCCModel } from './models';

export const externalCCStore: ExternalCCModel = {
  meetingId: '',
  tenantId: '',
  userId: '',
  participantId: '',
  currentLanguage: '',

  setMeetingId: action((state, payload) => {
    state.meetingId = payload;
  }),
  setTenantId: action((state, payload) => {
    state.tenantId = payload;
  }),
  setUserId: action((state, payload) => {
    state.userId = payload;
  }),
  setParticipantId: action((state, payload) => {
    state.participantId = payload;
  }),
  setCurrentLanguage: action((state, payload) => {
    state.currentLanguage = payload;
  }),
};
