import React, { createContext, useState } from 'react'
import useSettings from '../services/userSettings/useSettings'

type imageValue = {
  [key: string]: File | null
}

type TImageContext = {
  getImage: (value: string) => Promise<File | undefined>
}

export const ImageContext = createContext({} as TImageContext)

export const ImageProvider = ({ children }: ContextProviderProps) => {

  const { getUserImage } = useSettings()

  React.useEffect(() => {

    if (window)
      window.Buffer
  }, [])

  const [profileImages, setProfileImages] = useState<imageValue>({})

  const getImage = async (userId: string) => {
    const image = profileImages[userId]
    if (image !== undefined) return image
    const imageResult = await getUserImage(userId)
    setProfileImages({ ...profileImages, [userId]: imageResult })
    return imageResult
  }

  return <ImageContext.Provider
    value={{
      getImage
    }}>
    {children}
  </ImageContext.Provider>

}
