import { action } from 'easy-peasy';
import { IPopUpModel } from './models';

export const popUpStore: IPopUpModel = {
  isPopUpOpen: false,
  has30SecPassed: true,

  updateIsPopUpOpen: action((state, payload) => {
    state.isPopUpOpen = payload;
  }),
  update30Sec: action((state, payload) => {
    state.has30SecPassed = payload;
  }),
};
