import { action } from 'easy-peasy';
import { UserConfigurationModel } from './models';

export const userConfigurationStore: UserConfigurationModel = {
  userConfiguration: {
    spokenLanguages: '',
    translationLanguage: '',
    preferredFont: '',
    fontSize: 18,
    transcriptionSpeed: 'medium',
  },
  participant: null,

  updateUserConfiguration: action((state, payload) => {
    state.userConfiguration = { ...state.userConfiguration, ...payload };
  }),
  setParticipant: action((state, payload) => {
    state.participant = payload;
  }),
};
