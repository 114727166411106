import * as microsoftTeams from '@microsoft/teams-js';
import { action, thunk } from 'easy-peasy';
import { navigate } from 'gatsby';
import { ITeamsUserStoreModel } from './models';

export const userStore: ITeamsUserStoreModel = {
  userInfo: undefined,

  updateUserInfo: action((state, payload) => {
    state.userInfo = payload;
  }),
  initUserSidePanel: thunk(async (actions) => {
    const theme = await microsoftTeams.app
      .initialize()
      .then(async (_) => {
        const { user, chat, page, app } = await microsoftTeams.app.getContext();
        if (page.frameContext === 'content')
          return navigate('/context-settings');
        if (chat && user) actions.updateUserInfo(user);
        return app.theme;
      })
      .catch((err) => console.log(err));
    return theme;
  }),

  initUserConfigurable: thunk(async (actions) => {
    await microsoftTeams.app
      .initialize()
      .then(async (_) => {
        await microsoftTeams.app
          .getContext()
          .then((context) => {
            if (context.user) actions.updateUserInfo(context.user);
          })
          .catch()
          .finally();
      })
      .catch((err) => console.log(err));
  }),

  initUserStaticTab: thunk(async (actions, onThemeChange) => {
    const teamsTheme = await microsoftTeams.app
      .initialize()
      .then(async () => {
        if (
          window.location.href.includes('/config') &&
          microsoftTeams.pages.config.isSupported()
        ) {
          microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.pages.config.setConfig({
              entityId: 'subtitlestab',
              contentUrl: window.location.origin + '/subtitles',
              suggestedDisplayName: 'Verbum',
            });
            saveEvent.notifySuccess();
          });
        }
        const theme = microsoftTeams.app.getContext().then((ctx) => {
          if (ctx.user) actions.updateUserInfo(ctx.user);
          return ctx.app.theme;
        });
        return theme;
      })
      .catch((error) => {
        console.log(error);
      });

    microsoftTeams.app.registerOnThemeChangeHandler(onThemeChange);

    return teamsTheme;
  }),
};
