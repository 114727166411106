import React from 'react';
import { TeamsContext } from "../../context/TeamsContext";
import * as microsoftTeams from '@microsoft/teams-js';

export const TeamsProvider = ({ children }: 
  { children: React.ReactNode }
) => {
  const [teamsContext, setTeamsContext] = React.useState<microsoftTeams.app.Context | null>(null);
  const [isReady, setIsReady] = React.useState<boolean>(false);

  const initializeTeamsApp = async () => {
    try {
      setIsReady(false);
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      setTeamsContext(context);
    } catch {
      console.error('Error initializing Teams app');
    } finally {
      setIsReady(true);
    }
  }

  React.useEffect(() => {
    initializeTeamsApp();
  }, []);


  return <TeamsContext.Provider value={{
    context: teamsContext,
    isReady,
    teamsSdk: microsoftTeams,
  }}>
    {children}
  </TeamsContext.Provider>;
};