import React, { createContext } from "react";

export const PopUpContext = createContext({
    isPopUpOpen: false,
    setIsPopUpOpen: (value: boolean) => { }
});

export const usePopUp = () => {
    const context = React.useContext(PopUpContext)
    return context
}