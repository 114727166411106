import { action } from 'easy-peasy';
import { CCInteractionProcessor } from '../services/closedCaptions/cc-interaction.processor';
import { CCInteraction } from '../services/closedCaptions/types';
import { ClosedCaptionsModel } from './models';

export const closedCaptionsStore: ClosedCaptionsModel = {
  interactionsBlackList: [],
  ccInteractions: [],
  userCCs: [],
  ccInteractionsIdx: 0,
  userCCsIdx: 0,
  socket: undefined,
  connectingSocket: false,
  socketChannel: '',

  addInteractionsBlackList: action((state, payload) => {
    const uniques = [...new Set([...state.interactionsBlackList, ...payload])];
    state.interactionsBlackList = uniques;
    if (state.interactionsBlackList.length > 200) {
      const copy = state.interactionsBlackList.slice(-200);
      state.interactionsBlackList = copy;
    }
  }),
  addCCInteraction: action((state, payload) => {
    const found = state.ccInteractions.find(
      (i) => i.interactionId === payload.interactionId
    );

    if (found) {
      Object.assign(found, {
        text: payload.text,
        timestamp: payload.timestamp,
        recognized: payload.recognized,
      });
    } else {
      const newInteraction: CCInteraction = {
        key: state.ccInteractionsIdx,
        interactionId: payload.interactionId,
        userName: payload.userName,
        userId: payload.userId,
        text: payload.text,
        recognized: payload.recognized,
        timestamp: payload.timestamp,
        targetLanguage: payload.targetLanguage,
        originalLanguage: payload.originalLanguage,
      };
      state.ccInteractionsIdx = state.ccInteractionsIdx + 1;
      state.ccInteractions.push(newInteraction);
    }
  }),
  setUsersCCs: action((state, payload) => {
    const uCCs =
      CCInteractionProcessor.interactionsToUserClosedCaptions(payload);
    const userCCsCopy = state.userCCs.filter((i) =>
      uCCs.find((y) => y.userId === i.userId)
    );

    for (let ucc of uCCs) {
      const { ccs, originalLanguage, targetLanguage, userName, userId } = ucc;
      const found = userCCsCopy.find((i) => i.userId === ucc.userId);
      if (found) {
        Object.assign(found, {
          ccs,
          originalLanguage,
          targetLanguage,
        });
      } else {
        userCCsCopy.push({
          key: state.userCCsIdx,
          userName,
          userId,
          ccs,
          originalLanguage,
          targetLanguage,
        });
        state.userCCsIdx = state.ccInteractionsIdx + 1;
      }
    }
    userCCsCopy.sort((a, b) => a.key! - b.key!);
    state.userCCs = userCCsCopy;
  }),
  shiftCCInteraction: action((state) => {
    state.ccInteractions = state.ccInteractions.filter(
      (i) => !state.interactionsBlackList.includes(i.interactionId)
    );
  }),
  clearCCInteractions: action((state) => {
    state.ccInteractions = [];
  }),
  setSocket: action((state, payload) => {
    state.socket = payload;
  }),
  setConnectingSocket: action((state, payload) => {
    state.connectingSocket = payload;
  }),
  setSocketChannel: action((state, payload) => {
    state.socketChannel = payload;
  }),
};
