import { action } from 'easy-peasy';
import { ILiveTranscriptModel } from './models';

export const transcriptStore: ILiveTranscriptModel = {
  transcriptMessages: [],
  addMessage: action((state, payload) => {
    const transcriptMessages = state.transcriptMessages;
    let existingTranscript = transcriptMessages.find(
      (x) => x.interactionId === payload.interactionId
    );
    if (!existingTranscript)
      state.transcriptMessages = [...state.transcriptMessages, payload];
  }),
  setTranscriptHistory: action((state, payload) => {
    const transcriptMessages = state.transcriptMessages;

    const filteredMessages = payload.filter(
      (payloadItem) =>
        !transcriptMessages.find(
          (item) => payloadItem.interactionId === item.interactionId
        )
    );
    state.transcriptMessages = [
      ...filteredMessages,
      ...state.transcriptMessages,
    ].sort((a, b) => (a.timestamp! > b.timestamp! ? 1 : -1));
  }),
  resetHistory: action((state) => {
    state.transcriptMessages = [];
  }),
};
