import { action } from 'easy-peasy';
import { IStoreModel } from './models';

export const botStore: IStoreModel = {
  botInviteStatus: 'not-joined',

  updateBotInviteStatus: action((state, payload) => {
    state.botInviteStatus = payload;
  }),
};
