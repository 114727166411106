exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-channel-settings-tsx": () => import("./../../../src/pages/channel-settings.tsx" /* webpackChunkName: "component---src-pages-channel-settings-tsx" */),
  "component---src-pages-config-tsx": () => import("./../../../src/pages/config.tsx" /* webpackChunkName: "component---src-pages-config-tsx" */),
  "component---src-pages-context-settings-tsx": () => import("./../../../src/pages/context-settings.tsx" /* webpackChunkName: "component---src-pages-context-settings-tsx" */),
  "component---src-pages-external-captions-tsx": () => import("./../../../src/pages/external-captions.tsx" /* webpackChunkName: "component---src-pages-external-captions-tsx" */),
  "component---src-pages-external-experience-tsx": () => import("./../../../src/pages/external-experience.tsx" /* webpackChunkName: "component---src-pages-external-experience-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meeting-live-transcript-tsx": () => import("./../../../src/pages/meeting/live-transcript.tsx" /* webpackChunkName: "component---src-pages-meeting-live-transcript-tsx" */),
  "component---src-pages-meeting-settings-tsx": () => import("./../../../src/pages/meeting/settings.tsx" /* webpackChunkName: "component---src-pages-meeting-settings-tsx" */),
  "component---src-pages-meeting-subtitles-tsx": () => import("./../../../src/pages/meeting/subtitles.tsx" /* webpackChunkName: "component---src-pages-meeting-subtitles-tsx" */),
  "component---src-pages-meeting-verbum-chat-tsx": () => import("./../../../src/pages/meeting/verbum-chat.tsx" /* webpackChunkName: "component---src-pages-meeting-verbum-chat-tsx" */),
  "component---src-pages-transcripts-tsx": () => import("./../../../src/pages/transcripts.tsx" /* webpackChunkName: "component---src-pages-transcripts-tsx" */)
}

