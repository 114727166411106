import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { PopUpProvider } from './src/components/providers/PopUpProvider';
import { TeamsProvider } from './src/components/providers/TeamsProvider';
import { ConfigurationProvider } from './src/context/ConfigurationContext';
import { ImageProvider } from './src/context/ImageContext';
import { gb } from './src/services/growthbook';
import store from './src/store/store';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (
  <TeamsProvider>
    <StoreProvider store={store}>
      <GrowthBookProvider growthbook={gb}>
        <PopUpProvider>
          <ConfigurationProvider>
            <ImageProvider>{element}</ImageProvider>
          </ConfigurationProvider>
        </PopUpProvider>
      </GrowthBookProvider>
    </StoreProvider>
  </TeamsProvider>
);
