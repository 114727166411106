import * as microsoftTeams from '@microsoft/teams-js';
import { action, thunkOn } from 'easy-peasy';
import { IMeetingStoreModel } from './models';

const saveGroupHandler = (saveEvent: microsoftTeams.pages.config.SaveEvent) => {
  microsoftTeams.pages.config.setConfig({
    entityId: 'subtitles',
    contentUrl: window.location.origin + '/context-settings',
    suggestedDisplayName: 'Verbum',
  });
  saveEvent.notifySuccess();
};
const saveMeetingHandler = (
  saveEvent: microsoftTeams.pages.config.SaveEvent
) => {
  microsoftTeams.pages.config.setConfig({
    entityId: 'subtitles',
    contentUrl: window.location.origin + '/meeting/subtitles',
    suggestedDisplayName: 'Verbum',
  });
  saveEvent.notifySuccess();
};

export const meetingStore: IMeetingStoreModel = {
  teamsMeeting: {
    meetingId: '',
    teamsClient: undefined,
    context: undefined,
  },

  updateTeamsMeeting: action((state, payload) => {
    state.teamsMeeting = { ...state.teamsMeeting, ...payload };
  }),

  initMeetingSidePanel: thunkOn(
    (action, storeActions) => storeActions.teamsUser.initUserSidePanel,
    async (actions, target) => {
      const theme = await microsoftTeams.app
        .initialize()
        .then(async (_) => {
          const { user, chat, page, app } =
            await microsoftTeams.app.getContext();
          if (chat && user) {
            actions.updateTeamsMeeting({
              meetingId: chat.id,
              teamsClient: app.host.clientType,
              context: page.frameContext,
              meetingStage: page.frameContext === 'meetingStage',
            });
          }

          return app.theme;
        })
        .catch((err) => console.log(err));
      return theme;
    }
  ),

  initMeetingConfigurable: thunkOn(
    (action, storeActions) => storeActions.teamsUser.initUserConfigurable,
    async (actions) => {
      microsoftTeams.app
        .getContext()
        .then((context) => {
          let meetingId = '';
          let teamsClientType;
          let meetingContext;
          if (context.chat) {
            microsoftTeams.pages.config.registerOnSaveHandler(saveGroupHandler);
            meetingContext = 'group';
            meetingId = context.chat.id;
          }
          if (context.meeting) {
            meetingContext = 'meeting';
            microsoftTeams.pages.config.registerOnSaveHandler(
              saveMeetingHandler
            );
            if (context.chat) {
              meetingId = context.chat.id;
            }
          }
          if (context.channel) {
            microsoftTeams.pages.config.registerOnSaveHandler(saveGroupHandler);
            meetingContext = 'channel';
            meetingId = context.channel.id;
          }
          if (context.app) teamsClientType = context.app.host.clientType;
          actions.updateTeamsMeeting({
            context: meetingContext,
            meetingId: meetingId,
            teamsClient: teamsClientType,
            meetingStage: context.page.frameContext === 'meetingStage',
          });
        })
        .catch()
        .finally();
    }
  ),

  initMeetingStaticTab: thunkOn(
    (action, storeActions) => storeActions.teamsUser.initUserStaticTab,
    async (actions, target) => {
      await microsoftTeams.app
        .initialize()
        .then(async (_) => {
          const { user, chat, page, app } =
            await microsoftTeams.app.getContext();
          if (chat && user) {
            actions.updateTeamsMeeting({
              meetingId: chat.id,
              teamsClient: app.host.clientType,
              context: page.frameContext,
              meetingStage: page.frameContext === 'meetingStage',
            });
          }
          return app.theme;
        })
        .catch((err) => console.log(err));
    }
  ),
};
