import { createStore } from 'easy-peasy';
import { botStore } from './bot.store';
import { closedCaptionsStore } from './closed-captions.store';
import { transcriptStore } from './live-transcript.store';
import { externalCCStore } from './external-cc.store';
import { meetingStore } from './meeting.store';
import { StoreModel } from './models';
import { notificationsStore } from './notifications.store';
import { popUpStore } from './pop-up.store';
import { userStore } from './teams-user.store';
import { userConfigurationStore } from './user-configuration.store';

const store = createStore<StoreModel>({
  bot: botStore,
  closedCaptions: closedCaptionsStore,
  liveTranscript: transcriptStore,
  teamsUser: userStore,
  meeting: meetingStore,
  userConfiguration: userConfigurationStore,
  popUp: popUpStore,
  notifications: notificationsStore,
  externalCC: externalCCStore,
});

export default store;
