import { useState } from 'react';
import useSettingsAPI from './useSettingsAPI';

const useSettings = (userId: string) => {
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const getUserConfiguration = (
    onSucess: (AxiosResponse: UserPreferences) => void,
    onError: (AxiosResponse: string) => void
  ) => {
    try {
      if (!userId) {
        return;
      }
      setLoadingUserInfo(true);

      return useSettingsAPI
        .getUserPreferencesConfig(userId)
        .then((response) => {
          if (response.status === 200) {
            onSucess(response.data);
          }
        })
        .catch((error) => {
          if (error.status) {
            return onError(error.response.data);
          }
          return onError('There was an error updating the data');
        });
    } catch (err) {
    } finally {
      setLoadingUserInfo(false);
    }
  };

  const getUserImage = (userId: string) => {
    try {
      if (!userId) return;
      setLoadingUserInfo(true);
      return useSettingsAPI
        .getUserProfileImage(userId)
        .then((response) => {
          if (response.status === 200) {
            const file = new File([response.data], 'newImg.png', {
              type: 'image/png',
            });
            return file;
          }
        })
        .catch((error) => {
          if (error.status) return error.response.data;
        });
    } catch (err) {
    } finally {
      setLoadingUserInfo(false);
    }
  };

  const updateUserConfiguration = (
    userSettings: UserSettings,
    organizerSettings: string[],
    onSucess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      if (!userId) return;
      setLoadingUserInfo(true);
      return useSettingsAPI
        .updateUserPreferencesConfig(userSettings, organizerSettings, userId)
        .then((response) => onSucess())
        .catch((error) => {
          if (error.status) return onError(error.response.data);
          return onError('There was an error updating the data');
        });
    } catch (err) {
    } finally {
      setLoadingUserInfo(false);
    }
  };

  return {
    loadingUserInfo,
    getUserConfiguration,
    updateUserConfiguration,
    getUserImage,
  };
};

export default useSettings;
